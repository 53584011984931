import React from 'react'
import { Control, FieldValues, UseFormHandleSubmit } from 'react-hook-form'
import { TransitionStatus } from 'react-transition-group'
import { GenericErrors } from '~components/ui/controlled/types'

export interface FluxStandardAction {
  type: string
  payload?: unknown
  error?: boolean
  meta?: unknown
}

export enum Status {
  NOTIFICATION = 'notification',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface Config {
  GRAPHQL_ENDPOINT: string
  EE_GRAPHQL_ENDPOINT: string
  GRAPHQL_REGION: string
  USER_POOL_ID: string
  USER_POOL_WEB_CLIENT_ID: string
  CONTRIBUTIONS_API_URL: string
  CONTRIBUTIONS_API_KEY: string
  RUM_CLIENT_TOKEN: string
  RUM_APP_ID: string
  GOOGLE_ANALYTICS_UA_ID: string
  GOOGLE_ANALYTICS_GA4_ID: string
  GIT_HASH: string
  ENV: string
  LOG_LEVEL: string
  IS_EXTERNAL?: string
  VERIFY_EMBEDDED_WIDGET_API_URL?: string
  EE_REDIRECT_URL?: string
  PLI_URL?: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AppData {
  config: Config
}

export interface FormProps {
  handleSubmit: UseFormHandleSubmit<FieldValues>
  control: Control
  errors?: GenericErrors
}

export type Mode = 'light' | 'dark'

// eslint-disable-next-line @typescript-eslint/ban-types
export type FCWithNoChildren<P = {}> = Omit<React.FC<P>, 'children'>

export interface Hub {
  id: string
  content: React.ReactElement | string
  link: string
  isDisabled?: boolean
  isLoading?: boolean
}

export interface SubscriptionsOption {
  value: string
  label: string
}

export interface Hubs {
  [key: string]: Hub[]
}

export type TTrendDirection = 'up' | 'down' | 'straight'

export interface Statistic {
  name: string
  value: string
  trendSlope?: number
  isBarChartEnabled?: boolean
  linkTo?: string
  isHighlighted?: boolean
  isMaxWidth?: boolean
  isSearchResultsPage?: boolean
  isWrapped?: boolean
}

export interface Rank {
  count: number
  trendDirection?: TTrendDirection
}

export enum DevFeature {
  SoSV = 'SoSV',
}

export enum SoSVFeatures {
  SoSV = 'sosv',
  PowerPointReport = 'powerpoint-report',
  SosvChartDataExport = 'sosv-chart-data-export',
}

export enum GeneralFeatures {
  CommercialCompliance = 'commercial-compliance',
  ViewExtraContentIds = 'view-extra-content-ids',
  RedirectToExpertsEcosystem = 'redirect-to-experts-ecosystem',
}

export enum ExpertEcosystemFeatures {
  ShowContactDetails = 'show-expert-contact-details',
}

export enum DigitalFeatures {
  DemoScientificXAndDigital = 'demo-scientific-x-and-digital',
}

export enum Features {
  Experts = 'experts',
  Science = 'science',
  Disclosures = 'disclosures',
  NetworkInformation = 'network-information',
  SocialInformation = 'social-information',
  AlertNotifications = 'alert-notifications',
  ShowClientNames = 'showing-client-name-in-the-list-of-subscriptions',
  DemoMode = 'demo-mode',
  InternalTestingGroup = 'internal-testing-group',
  ExternalTestingGroup = 'external-testing-group',
  PDFSummaryExport = 'pdf-summary-export',
  WordExport = 'word-export',
  DisplayGrants = 'display-grants',
  IqviaColours = 'iqvia-colours',
  AcceleratedInsights = 'accelerated-insights',
  InfluenceScores = 'influence-scores',
  CustomizableRanking = 'customizable-ranking',
}

export enum SearchCategories {
  booleanQuery = 'Boolean Query',
  freeText = 'Free Text',
  medicalTerm = 'medical term',
  fullName = 'full name',
  degree = 'degree',
  speciality = 'speciality',
  society = 'society',
  societyEvent = 'society event',
  societyEventCustomTerm = 'society event custom term',
  journal = 'journal',
  institution = 'institution',
  city = 'city',
  state = 'state',
  country = 'country',
  disclosures = 'disclosures',
  zip = 'zip',
}

export type NotificationType = 'none' | 'weekly' | 'monthly'

export type SearchQuerySynonyms = Record<string, boolean>

export interface SearchQueryValue {
  value: string
  category: string
  synonyms?: SearchQuerySynonyms
}

export interface SearchedQueryValues extends SearchQueryValue {
  id: string
}

export type SearchedQueryFilters = (string | string[])[]

export interface Search {
  isSaved: boolean
  queryItems: SearchedQueryValues[]
  queryFilters: SearchedQueryFilters
  id?: string
  notificationType?: NotificationType
  title?: string
}

export enum ContentTypes {
  presentations = 'presentations',
  treatmentGuidelines = 'treatment-guidelines',
  clinicalTrials = 'clinical-trials',
  grants = 'grants',
  journals = 'journals',
  nihGrants = 'nih-grants',
}

export interface Product {
  name: string
  denominationColor: string
}

export interface ProductGroupProduct {
  label: string
  searchTerms: string[]
}

export interface ProductGroup {
  id: string
  name: string
  hidden?: boolean | null
  products: ProductGroupProduct[]
}

export interface MapBounds {
  southWest: {
    lng: number
    lat: number
  }
  northEast: {
    lng: number
    lat: number
  }
}

export type CumulatingType = 'cumulative' | 'non-cumulative'
export type GraphType = 'share' | 'counts'
export type WeighteningType = 'weighted' | 'unweighted'
export type DataSetType = 'experts' | 'science'
export type ExportType = 'png' | 'jpeg' | 'xls'
export enum SoSVContentType {
  journalArticle = 'journal-article',
  presentation = 'presentation',
}
export enum DataSet {
  EXPERTS = 'experts',
  SCIENCE = 'science',
}

export enum DateBreakdown {
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year',
}

export interface DateInterval {
  breakDownBy: DateBreakdown
  startDate: Date
  endDate: Date
}

export enum SoSVTimeframe {
  TwelveWeeks = 'twelveWeeks',
  OneYear = 'oneYear',
  ThreeYears = 'threeYears',
  TenYears = 'tenYears',
  CustomDateRange = 'customDateRange',
}

export const DefaultSoSVTimeFrame: SoSVTimeframe = SoSVTimeframe.ThreeYears
export const FallbackSoSVTimeFrame: SoSVTimeframe = SoSVTimeframe.TenYears

export type CollectionType =
  | 'products'
  | 'experts'
  | 'journals'
  | 'events'
  | 'societies'
  | 'countries'
  | 'cities'
  | 'locations'
  | 'societyEventsLocation'
  | 'launchAnalogue'

export const sosvTimeframeRanges: Record<DateBreakdown, number> = {
  week: 3,
  month: 1,
  quarter: 3,
  year: 10,
}

export enum DefaultCoordinates {
  SouthWestLng = -182.4609375,
  SouthWestLat = -56.752722872057355,
  NorthEastLng = 182.4609375,
  NorthEastLat = 72.28906720017675,
}

export const sosvTimeframeLabels: Record<Exclude<SoSVTimeframe, SoSVTimeframe.CustomDateRange>, string> = {
  twelveWeeks: 'last 3 months',
  oneYear: 'last year',
  threeYears: 'last 3 years',
  tenYears: 'last 10 years',
}

export interface GeographyFilter {
  country: string
  state?: string | null
  city?: string | null
}

export type Scale = 'lowest' | 'low' | 'medium' | 'high' | 'highest'

export interface WithAnimation {
  animationState: TransitionStatus
}

export const maxProfilesForSearch = 900

export enum SmartListType {
  Geographic = 'geographic',
  Speciality = 'speciality',
}

export interface SocialProfile {
  linkedInUrl: string | null
  twitterUsername?: string | null
  twitterFollowersCount: number
  twitterUrl: string | null
}

export interface Contact {
  address1: string | null
  city: string | null
  country: string | null
  state: string | null
  zip: string | null
  firstEmail: string | null
  phoneNumber: string | null
}

export interface SearchPill {
  category: string
  value: string
  synonyms?: string[] | null
}

export enum AcceptedPillCategories {
  'boolean query',
  'free text',
  'medical term',
  'full name',
  'degree',
  'speciality',
  'society',
  'society event',
  'society event custom term',
  'journal',
  'institution',
  'city',
  'state',
  'country',
  'disclosures',
  'zip',
}
